import React from 'react';
import { Transition } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDown } from '../components/svgs/ChevronDown';
import { useTrapFocus } from '../lib/hooks/useTrapFocus';
import { FCWithChildren } from '../lib/types';

interface SlideOutProps {
  isOpen: boolean;
  onClose: () => void;
  afterLeave?: () => void;
  className?: string;
  ariaLabel?: string;
}

const SlideOut: FCWithChildren<SlideOutProps> = ({
  isOpen,
  onClose,
  afterLeave,
  className,
  children,
  ariaLabel,
}) => {
  const transition = 'transition-all duration-300';
  const slideIn = 'translate-x-0 opacity-100';
  const slideOut = 'translate-x-full opacity-0';

  const { ref: slideOutRef, onClose: trapFocusOnClose } =
    useTrapFocus<HTMLDivElement>({
      focusDelay: 200,
    });

  const handleClose = () => {
    trapFocusOnClose();
    onClose();
  };

  return (
    <Transition
      role="dialog"
      id="slide-out-dialog"
      aria-label={ariaLabel}
      aria-modal="true"
      className={`p-4 lg:p-6 fixed bg-white w-full h-full top-0 left-0 transform z-50 overflow-y-scroll ${className}`}
      enter={transition}
      enterFrom={slideOut}
      enterTo={slideIn}
      leave={transition}
      leaveFrom={slideIn}
      leaveTo={slideOut}
      show={isOpen}
      afterLeave={afterLeave}
      appear
      ref={slideOutRef}
    >
      <>
        <div className="flex m-2 mb-7 text-theme-primary">
          <button
            className="flex cursor-pointer items-center print:hidden"
            data-testid="faq-back-button"
            onClick={handleClose}
            id="faq-back-button"
          >
            <ChevronDown
              aria-hidden="true"
              className="w-4 fill-current transform-gpu rotate-90"
            />
            <span className="text-sm pl-2 font-bold">
              <FormattedMessage
                defaultMessage="Back"
                description="SlideOut back button text"
              />
            </span>
          </button>
        </div>
        {children}
      </>
    </Transition>
  );
};

export default SlideOut;
